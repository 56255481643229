@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  overflow-x: hidden;
  font-family: Poppins;
}
.burger_icon {
  display: none !important;
}
.mbl_mobile_main {
  display: none;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

nav,
ul {
  list-style: none;
}

.common_width {
  width: 1300px;
  margin: 0rem auto;
}

.circle {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
header {
  position: relative;
  z-index: 9;
}
.firstcircle {
  background-color: #6557fe3f;
  top: -40px;
  right: -250px;
  -webkit-filter: blur(125px);
  width: 50vw;
  height: 50vw;
}
.secondcircle {
  width: 30vw;
  height: 30vw;
  top: 350px;
  left: 150px;
  -webkit-filter: blur(125px);
  background-color: #12a0ff5f;
}
.thirdcircle {
  width: 30vw;
  height: 30vw;
  top: 850px;
  right: 150px;
  -webkit-filter: blur(125px);
  background-color: #12a0ff5f;
}
.fourthcircle {
  width: 30vw;
  height: 30vw;
  top: 1150px;
  left: 150px;
  -webkit-filter: blur(125px);
  background-color: #6557fe3f;
}
.Landing {
  min-height: 100vh;

  overflow: hidden;
  background-color: #081c34;
  /* font-family: "Orbitron", sans-serif; */
}
.Landing .common_width {
  width: 1140px;
}
.landing_wrapper {
  background-position: center;
  background-size: cover;
}
.Landing .MuiOutlinedInput-notchedOutline {
  border: none;
}
.Landing header {
  height: 120px;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem !important;
}
.Landing header > a {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 35px;
  display: flex;
  align-items: center;
}
.Landing header > a span {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0rem 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Landing header > a span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;

  border: 5px solid transparent;
  animation: 2s opacityDone ease;
  /* background: linear-gradient(to right, #8878ff, #d33ed9); */
  background: linear-gradient(45deg, #d33ed9, #0fa4fd) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
@keyframes opacityDone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Landing header nav {
  display: flex;

  align-items: center;
}
.Landing header nav a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}
.Landing header nav > * + * {
  margin-left: 3rem;
}
iframe {
  display: none !important;
}

.Landing main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 2rem;
  z-index: 2;
  position: relative;
  height: 700px;
  margin-bottom: 3rem !important;
}
.Landing main .button_wrapper {
  display: flex;
  align-items: center;
}
.Landing main .button_wrapper > * + * {
  margin-left: 1rem;
}
.Landing main .image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.Landing main .image_wrapper img {
  height: 100%;
}
.Landing main .presentation_area {
  width: 100%;
}
.Landing main h1 {
  /* font-family: Orbitron; */
  font-size: 45px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.Landing main p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #b7bcbe;
  margin: 2rem 0rem;
}
.Landing main .input_wrapper {
  height: 55px;
  width: 476px;
  background: #0c111680;

  padding-left: 1rem;
  display: flex;
  border-radius: 10px;
}
.Landing main .input_wrapper input {
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  outline: none;
  text-align: left;
  background: transparent;
  border: none;
  color: #fff;
}
.Landing main .input_wrapper button,
.join_wrapper .input_wrapper button {
  /* font-family: "Orbitron", sans-serif; */
  min-width: 158px;
  background: linear-gradient(to right, #8878ff, #d33ed9);
  border: none;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 10px;
  color: #fff;
}

/* how_it_works */
.how_it_works {
  padding: 8rem 0rem;
  z-index: 2;
  position: relative;
}
.how_it_works .top_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8rem;
}
.how_it_works .top_area h1 {
  /* font-family: "Orbitron", sans-serif; */
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
.how_it_works .top_area p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  width: 73%;
}
.how_it_works .boxes_area {
  display: flex;
}
.how_it_works .boxes_area > *:nth-child(1) {
  width: 16.5%;
}
.how_it_works .boxes_area > *:nth-child(3) {
  width: 16.5%;
}
.how_it_works .boxes_area .box {
  text-align: center;
  color: #fff;
}
.how_it_works .boxes_area .box p {
  margin-top: 20px;
  font-family: "Poppins";
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}
.how_it_works .boxes_area > *:nth-child(2) {
  flex: 1;
}
.how_it_works .boxes_area > *:nth-child(2) p {
  width: 35%;
}
.how_it_works .boxes_area .box .boximg {
  height: 80px;
}
.how_it_works .boxes_area .box .boximg > * {
  font-size: 60px;
  color: #efa00b;
}
.portfilio {
  padding: 10rem 0rem;
}
.portfilio > * {
  display: flex;
  justify-content: center;
}
.portfilio img {
  width: 40%;
}
.Landing .row_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 2rem;
  padding-bottom: 10rem;
}
.Landing .row_area.right .img_wrapper {
  text-align: right;
}
.Landing .row_area .img_wrapper img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.features .row_area .img_wrapper img {
  height: unset;
  width: 70%;
}

.Landing .row_area .stars_wrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.Landing .row_area .stars_wrapper > * {
  color: #efa00b;
}
.Landing .row_area .presentation {
  width: 80%;
}
.Landing .row_area .presentation {
  padding-bottom: 5rem;
}
.Landing .row_area .presentation h1 {
  /* font-family: "Orbitron", sans-serif; */
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.Landing .row_area .presentation h1 {
  width: 70%;
}
.Landing .row_area .presentation p,
.Landing .row_area .presentation li {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #cecfd0;
  margin-top: 2.5rem;
}
.Landing .row_area .presentation ul {
  list-style: disc;
  padding-left: 2rem;
  margin-top: 20px;
}
.Landing .row_area .presentation ul + p {
  margin-top: 1rem !important;
}
.Landing .row_area .presentation li {
  margin-top: 5px;
  font-size: 16px;
}
.review_wrapper {
  margin-bottom: 10rem;
}
.review_wrapper_gap > *:nth-child(4) {
  padding-bottom: 2rem !important;
}

.join_wrapper {
  background: linear-gradient(to right, #1d9bf01a, #efa00b1a);
  padding: 4rem 0rem;
}
.join_wrapper .content_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
}
.join_wrapper h1 {
  /* font-family: "Orbitron", sans-serif; */
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
.join_wrapper .input_wrapper {
  height: 55px;
  width: 100%;
  background: #0c111680;
  align-items: center;
  padding-left: 1rem;
  display: flex;
  border-radius: 10px;
}
.join_wrapper .input_wrapper input {
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  outline: none;
  text-align: left;
  background: transparent;
  border: none;
  color: #fff;
}
.Landing main .input_wrapper button {
  /* font-family: "Orbitron", sans-serif; */
  min-width: 158px;
  background: linear-gradient(to right, #8878ff, #d33ed9);
  border: none;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 10px;
}

.landing_footer {
  padding: 5rem 0rem;
  color: #fff;
}
.landing_footer .logo {
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}
.landing_footer > div ul:first-child li:first-child {
  margin-bottom: 2rem;
}
.landing_footer > div ul:first-child li {
  line-height: 35px;
  font-size: 15px;
}
.landing_footer a {
  color: #fff;
}
.landing_footer > div {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  grid-gap: 5rem;
}
.landing_footer > div .head {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 3rem;
}
.landing_footer > div ul > * + * + * {
  margin-top: 1.5rem;
}
.landing_footer > div ul a {
  font-size: 15px;
  text-decoration: none;
}

.team_wrapper {
  padding: 5rem 0rem;
  padding-bottom: 14rem;
}

.team_wrapper > h1,
.review_wrapper > h1 {
  text-align: center;
  color: #fff;
  font-size: 36px;
  margin-bottom: 5rem;
}
.extend_page h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
  margin-bottom: 2rem;
  padding-top: 5rem;
  text-align: left;
}
.team_wrapper .cards_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.team_wrapper .team_landing > div:first-child {
  grid-column: span 3;
  text-align: center;
}
.team_wrapper .team_landing > div img {
  width: 450px;
}
.team_wrapper .link_wrapper,
.review_wrapper .link_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.team_wrapper .link_wrapper a,
.review_wrapper .link_wrapper a {
  /* font-family: "Orbitron", sans-serif; */
  min-width: 158px;
  background: linear-gradient(to right, #8878ff, #d33ed9);
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  height: 50px;
}
.TeamCard {
  color: #fff;
}
.TeamCard > img {
  width: 100%;
  height: 500px;
  object-fit: cover;

  border-radius: 10px;
}
.TeamCard h1 {
  padding: 1rem 2rem;
  margin-top: 1rem;

  font-size: 20px;
  text-align: center;
}
.TeamCard p {
  text-align: center;
  font-size: 15px;
}
.TeamCard ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  color: #fff;
}
.TeamCard ul > * + * {
  margin-left: 0.5rem;
}
.TeamCard ul a {
  color: #fff;
}
@media screen and (max-width: 1350px) {
  .Landing .common_width {
    width: 90%;
  }
}
@media screen and (max-width: 1050px) {
  .Landing header nav > * + * {
    margin-left: 1rem;
  }
  .Landing .row_area,
  .join_wrapper .content_area {
    grid-template-columns: 1fr;
  }
  .Landing .row_area.right > *:first-child {
    order: 2;
  }
  .Landing .row_area.right > *:last-child {
    order: 1;
  }
  .Landing .row_area.right .img_wrapper {
    text-align: left;
  }
  .Landing .row_area .presentation {
    width: 100%;
  }
  .Landing .row_area .img_wrapper {
    text-align: center !important;
  }
  .Landing .row_area .presentation h1,
  .join_wrapper h1 {
    text-align: center;
    width: 100%;
  }
  .Landing .row_area .presentation p {
    text-align: center;
    width: 100%;
  }
  .how_it_works .boxes_area {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  .how_it_works .top_area {
    grid-template-columns: 1fr;
  }
  .how_it_works .top_area h1,
  .how_it_works .top_area p,
  .Landing main h1,
  .Landing main p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .how_it_works .top_area p {
    margin-top: 2rem;
  }
  .how_it_works .boxes_area .box p {
    width: 30% !important;
  }
  .how_it_works .boxes_area .box {
    width: 100%;
  }
  .portfilio img {
    width: 100%;
  }
  .Landing main {
    grid-template-columns: 1fr;
    height: 100%;
  }
  .Landing main > *:first-child {
    order: 2;
  }
  .Landing header {
    height: 100px;
    margin-bottom: 2rem !important;
  }
  .mbl_mobile_main {
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

  .Landing main > *:last-child {
    order: 1;
    height: unset;
    display: none;
  }

  .Landing main > *:last-child img {
    height: unset;
    width: 50%;
  }
  .Landing main .presentation_area {
    width: 100%;
  }
  .Landing main .input_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 800px) {
  .burger_icon {
    display: block !important;
    color: #fff;
  }

  .team_wrapper .cards_wrapper {
    grid-template-columns: 1fr;
  }
  .Landing .row_area .stars_wrapper {
    justify-content: center;
  }
  .Landing main {
    z-index: 1;
  }
  .Landing header {
    position: relative;
    z-index: 9;
  }
  .Landing header nav {
    position: fixed;
    left: -100%;
    top: 0px;
    flex-direction: column;
    background-color: #081c34;
    padding: 2rem 0rem;
    width: 200px;
    height: 100vh;
    z-index: 9999;
    transition: 0.5s ease all;
  }
  .Landing header nav.active {
    left: 0%;
  }
  .Landing header nav > * + * {
    margin-left: 0px;
    margin-top: 2rem;
  }
  .landing_footer > div {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  .landing_footer > div .head {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 650px) {
  .Landing h1 {
    font-size: 20px !important;
  }
  .Team .team_wrapper > h1,
  .Landing .Users > h1 {
    font-size: 25px !important;
  }

  .Team .team_wrapper > p,
  .Landing .Users > p {
    width: 100% !important;
  }

  .Landing main h1 {
    font-size: 25px !important;
    line-height: unset;
  }
  .Landing p {
    font-size: 14px !important;
  }
  .how_it_works .boxes_area .box p {
    width: 250px !important;
  }
  .how_it_works .top_area p {
    width: 330px !important;
  }
  .circle {
    display: none !important;
  }
}
@media screen and (max-width: 550px) {
  .Landing main .input_wrapper {
    width: 100%;
  }
  .how_it_works {
    padding-bottom: 0px;
  }
  .portfilio {
    padding: 5rem 0rem;
  }
  .Landing main .input_wrapper,
  .join_wrapper .input_wrapper {
    flex-direction: column;
    padding: 0rem;
    height: 100%;
  }
  .Landing main .input_wrapper > *,
  .join_wrapper .input_wrapper > * {
    min-height: 45px;
  }
  .Landing main .input_wrapper > input,
  .join_wrapper .input_wrapper > input {
    min-height: 55px;
    padding: 0rem 1rem;
  }
  .Landing main .input_wrapper button,
  .join_wrapper .input_wrapper button {
    min-width: 100%;
    font-size: 13px;
  }
  .Landing .row_area .img_wrapper img {
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  .how_it_works .top_area p,
  .how_it_works .boxes_area .box p {
    width: 100% !important;
  }
  .Landing h1 {
    font-size: 18px !important;
  }
}
