.contact-area {
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  grid-gap: 4rem;
  width: 100%;
}

.contact-area > .left-side h1 {
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 2.8rem;
}
.text {
  white-space: pre-line;
  white-space: pre-wrap;
}
.contact-area form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.4rem;
}
.contact-area form > * {
  grid-column: span 2;
}
.contact-area form > *.select-wrapper {
  grid-column: span 1;
}
.CodeMirror-cursor {
  border-left-color: #fff !important;
}
.editor-preview,
.editor-preview-side {
  background: #000211 !important;
}
.CodeMirror {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #32333f !important;
}
.editor-toolbar {
  border-color: #32333f !important;
}
.editor-toolbar a {
  color: #fff !important;
  opacity: 1 !important;
}
.editor-toolbar a:hover {
  background-color: #0c0d37 !important;
}
.contact-area form input,
.contact-area form textarea,
.contact-area form .select .head {
  display: block;
  width: 100%;
  flex: 1;
  border: 1px solid #32333f;
  border-radius: 5px;
  height: 45px;
  background-color: transparent;
  outline: none;
  padding: 0rem 1rem;
  color: #a9aaaf;
  margin-top: 8px;
}

.contact-area form .select .head {
  padding: 0rem 0.8rem;

  display: flex;

  font-size: 14px;

  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}
.contact-area form .select {
  position: relative;
}
.contact-area form .select .head > * {
  pointer-events: none;
}
.contact-area form .select .head span {
  white-space: nowrap;
  overflow: hidden;
}
.contact-area form .select ul {
  background-color: #fff;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 8;
}
.locations_list {
  max-height: 400px;
  overflow: auto;
}
.contact-area form .select ul.active {
  display: block;
}
.contact-area form .select ul li {
  color: #000;
}
.contact-area form .select ul li label {
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0.8rem 1rem;
}
.contact-area form .select .head > * {
  font-size: 12px;
}
.contact-area form .select ul li input {
  display: none;
}
.contact-area form input::placeholder,
.contact-area form textarea::placeholder {
  color: #a9aaaf;
}
.contact-area form label {
  font-family: "Poppins";
}
.contact-area form textarea,
.contact-area form input,
.contact-area select,
.contact-area form .select .head {
  font-family: "Poppins";
}
.contact-area form textarea {
  height: 250px;
  resize: none;
  padding: 1rem;
}

.contact-area form .drag_files {
  height: 100px;
  margin: 10px 0rem;
  background-color: #1a1b29;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #464752;
  border-radius: 8px;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}
.contact-area form small {
  color: #c8c8cb;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.contact-area form button {
  background-color: #fff;
  width: 190px;
  height: 40px;

  color: #020234;
  outline: none;

  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s ease all;
}
.contact-area form button:hover,
.popup-wrapper .popupContent .pureContent a:hover {
  background: #d4a211;
  color: #fff;
}

/* .contact-area right side */
.contact-area .right-side {
  padding-top: 2rem;
}
.contact-area .right-side img {
  width: 100%;
}
.contact-area .right-side .content-area {
  background-color: #03071e;
  padding: 3rem 1.5rem;
  transition: 0.3s ease transform;
}
.contact-area .right-side .content-area:hover {
  transform: scale(1.06);
}
.contact-area .right-side .content-area > h1,
.contact-area .right-side .content-area > h2 {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  margin-bottom: 2rem;
}
.contact-area .right-side .content-area > h1 span {
  color: #d4a211;
}
.contact-area .right-side .content-area > h2 {
  margin-bottom: 25px;
  text-align: left;
  position: relative;
}
.contact-area .right-side .content-area > h2::after {
  position: absolute;
  top: 40px;
  width: 18%;
  height: 3px;
  left: 0px;
  content: "";
  background-color: #d4a211;
}
.contact-area .right-side .content-area > p {
  font-size: 14px;
  color: #fafafa;
}
.contact-area .right-side .content-area ul {
  margin-top: 2rem;
}
.contact-area .right-side .content-area ul li {
  display: flex;
  align-items: center;
}
.contact-area .right-side .content-area ul .icon-wrapper {
  width: 30px;
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
}
.contact-area .right-side .content-area ul > * + * {
  margin-top: 1rem;
}
.contact-area .right-side .content-area ul p {
  font-size: 14px;
}

@media screen and (max-width: 1260px) {
  .contact-area {
    grid-template-columns: 1fr;
  }
  .contact-area .right-side img {
    display: none;
  }
  .contact-area .right-side {
    padding-top: 4rem;
  }
}
@media screen and (max-width: 800px) {
  .contact-area {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .contact-area form > *.select-wrapper {
    grid-column: span 2;
  }
}
@media screen and (max-width: 600px) {
  .popup-wrapper .popupContent .pureContent {
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .contact-area > .left-side h1 {
    font-size: 2rem;
  }
  .contact-area .right-side .content-area > h1,
  .contact-area .right-side .content-area > h2 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 420px) {
  .contact-area .right-side .content-area ul li p {
    word-break: break-all;
  }
  .popup-wrapper .popupContent .pureContent button {
    margin-top: 1rem;
  }
}
