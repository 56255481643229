.Users > h1 {
  font-size: 50px;
  margin-bottom: 0px;
  color: #fff;
  margin-bottom: 2rem;
}

.Users > p {
  color: #fff;
  width: 70%;
  margin-bottom: 6rem;
  line-height: 30px;
  font-family: "Poppins", arial !important;
}
