.PrivacyContent {
  color: #fff;
  margin-bottom: 10rem !important;
}

.PrivacyContent p,
li {
  font-family: "Poppins", arial !important;
}
.PrivacyContent h1 {
  margin-bottom: 3rem;
}
.PrivacyContent h2,
.PrivacyContent h1:not(:first-child) {
  margin-bottom: 2rem;
  font-size: 25px;
}
.PrivacyContent h5 {
  color: #ecae39;
  font-size: 20px;
  margin-bottom: 1rem;
}
.PrivacyContent p {
  margin-bottom: 1.5rem;
  line-height: 35px;
  font-size: 15px;
}
.PrivacyContent ul {
  padding-left: 2rem;
  margin-bottom: 1.5rem;
}
.PrivacyContent ul > * + * {
  margin-top: 1rem;
}
.PrivacyContent ul a {
  color: #fff;
}
.PrivacyContent ul + h2 {
  margin-top: 4rem;
}
.PrivacyContent a {
  color: #ecae39 !important;
}
